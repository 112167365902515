<template>
  <div>
    <a-descriptions
      title="运营商接口"
      :column="1"
      class="simiot-descriptions simiot-descriptions-max-9"
    >
      <a-descriptions-item label="内部API服务器域名">
        {{ apiAccount.api_server_domain }}
      </a-descriptions-item>

      <a-descriptions-item label="接口类型">
        {{ apiAccount.account_type }}
      </a-descriptions-item>

      <a-descriptions-item label="API访问路径">
        {{ apiAccount.api_url }}
      </a-descriptions-item>

      <a-descriptions-item label="接口用户名">
        {{ apiAccount.api_username }}
      </a-descriptions-item>

      <a-descriptions-item label="接口密码">
        {{ apiAccount.api_password }}
      </a-descriptions-item>

      <a-descriptions-item label="接口密钥" v-if="isCmp">
        {{ apiAccount.api_secret_key }}
      </a-descriptions-item>

      <a-descriptions-item label="Rest 用户名" v-if="isDcp">
        {{ apiAccount.dcp_rest_username }}
      </a-descriptions-item>

      <a-descriptions-item label="Rest 密码" v-if="isDcp">
        {{ apiAccount.dcp_rest_password }}
      </a-descriptions-item>

      <a-descriptions-item label="调用限频">
        {{ apiAccount.rate_display }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
export default {
  name: 'ShowChinaTelecomApiAccount',
  props: {
    apiAccount: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isCmp: this.apiAccount.account_type === 'CMP',
      is5gCmp: this.apiAccount.account_type === '5GCMP',
      isDcp: this.apiAccount.account_type === 'DCP'
    }
  }
}
</script>

<style scoped>

</style>
